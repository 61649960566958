import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/404.png";

function PageNotFound() {
  return (
    <section className="d-flex align-items-center min-vh-100 py-5">
      <div className="container">
        <div className="row flex-lg-row-reverse align-items-center">
          <div className="col-lg-6">
            <div className="lc-block">
              <img
                className="img-fluid rounded shadow mb-4"
                src={image}
                loading="lazy"
                width="900"
                height="600"
                alt='page not found'
              />
            </div>
          </div>
          <div className="col-md-6 text-center text-md-start ">
        <div className="lc-block mb-3">
          <div editable="rich">
            <h1 className="fw-bold h4">
              PAGE NOT FOUND!
              <br />
            </h1>
          </div>
        </div>
        <div className="lc-block mb-3">
          <div editable="rich">
            <h1 className="display-1 fw-bold text-muted">Error 404</h1>
          </div>
        </div>
        <div className="lc-block mb-5">
          <div editable="rich">
            <p className="rfs-11 fw-light">
              {" "}
              The page you are looking for was moved, removed or might never
              existed.
            </p>
          </div>
        </div>
        <div className="lc-block">
          <Link className="btn btn-lg btn-primary" to="/" role="button">
            Back to homepage
          </Link>
        </div>
      </div>
        </div>
      </div>
    </section>
  );
}


export default PageNotFound;
