import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import { getUsers } from "../util/http";
import { CSVLink } from "react-csv";

function Admin() {
  const [users, setUsers] = useState([]);

  async function callGetUsers() {
    try {
      const response = await getUsers();
      setUsers(response);
    } catch (error) {
      console.log("🚀 ~ getUsers ~ error:", error);
    }
  }

  useEffect(() => {
    callGetUsers();
  }, []);

  const headers = [
    { label: "User Name", key: "fullname" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
    { label: "Comment", key: "comment" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip Code", key: "zip" },
  ];

  const csvReport = {
    filename: "SpeakYourMenu_ContactList.csv",
    headers: headers,
    data: users,
  };

  return (
    <>
      <Nav />
      <div className="container mt-5">
        <CSVLink {...csvReport}>
          <button className="btn btn-primary">Download Users List</button>
        </CSVLink>
      </div>
      <div className="container my-5 border rounded p-4">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Comment</th>
              <th scope="col">City</th>
              <th scope="col">State</th>
              <th scope="col">Zip</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              return (
                <tr key={user.fullname}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.fullname}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.comment}</td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.zip}</td>
                </tr>
              );
            })}
            {/* <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
}

export default Admin;
