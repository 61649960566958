import React, { useEffect, useState } from "react";

import { storeUser } from "../util/http";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

function CallToAction() {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({})
  const [userInfo, setUserInfo] = useState({
    fullname: "",
    email: "",
    city: "",
    state: "",
    zip: "",
    role: "",
    comment: "",
  });

  function isValidEmail(email) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({ ...prevState, [name]: value }));
  }

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleSubmit(e) {
    e.preventDefault();
    let validationErrors = {};

    if (userInfo.email.length === 0 || !isValidEmail(userInfo.email)) {
      validationErrors.email = 'Email is invalid.'
    }

    if (userInfo.fullname.length === 0){
      validationErrors.fullname = 'Please enter a name.'
    }

    if (userInfo.city.length === 0) {
      validationErrors.city = 'Please enter a city.'
    }

    if (userInfo.state.length === 0) {
      validationErrors.state = 'Please choose a state.'
    }

    if (userInfo.zip.length === 0) {
      validationErrors.zip = 'Please enter a zip code.'
    }

    if (userInfo.role.length === 0) {
      validationErrors.role = "Please choose a role."
    }

    if (userInfo.comment.length === 0) {
      validationErrors.comment = 'Please tell us what you want out of this app.'
    }

    
    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors)
      console.log(Object.keys(validationErrors).length)
      setErrors(validationErrors);
    } else {
      // Handle the successful form submission, e.g., sending formData to a server
      try {
       await storeUser(userInfo)
       handleClose();
      } catch(error) {
        console.log("🚀 ~ handleSubmit ~ error:", error.message)
        
      }
      console.log('Data sent.')
      setErrors({}); // Clear any previous errors
    }
  }

  return (
    <>
      <div className="container text-center py-4">
        <div className="lc-block card border-0 text-center rounded p-4 p-lg-6">
          <div className="row card-body mb-3 mb-lg-4">
            <div className="col-xl-11 col-xxl-10 mx-auto">
              <div className="lc-block mb-5">
                <p className="text-uppercase" editable="inline">
                  Ready to create the language of your menu?
                </p>
                <h3 editable="inline" className="fw-bold display-2">
                  Sign up for{" "}
                  <span className="text-primary">Speak Your Menu</span> today
                </h3>
              </div>

              <div className="lc-block mb-4">
                <div className="d-flex flex-wrap justify-content-center gap-3">
                  <div className="d-inline-flex gap-1 lc-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      fill="currentColor"
                      className="text-primary my-auto flex-shrink-0"
                      viewBox="0 0 16 16"
                      lc-helper="svg-icon"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                    </svg>
                    <p editable="inline" className="lead my-auto">
                      Stay informed as we build new features
                    </p>
                  </div>
                  <div className="d-inline-flex gap-1 lc-block ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      fill="currentColor"
                      className="text-primary my-auto flex-shrink-0"
                      viewBox="0 0 16 16"
                      lc-helper="svg-icon"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                    </svg>
                    <p editable="inline" className="lead my-auto">
                      Be the first to know about our launch day
                    </p>
                  </div>
                  <div className="d-inline-flex gap-1 lc-block ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      fill="currentColor"
                      className="text-primary my-auto flex-shrink-0"
                      viewBox="0 0 16 16"
                      lc-helper="svg-icon"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                    </svg>
                    <p editable="inline" className="lead my-auto">
                      Tell us what features you want to see
                    </p>
                  </div>
                  <div className="d-inline-flex gap-1 lc-block ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      fill="currentColor"
                      className="text-primary my-auto flex-shrink-0"
                      viewBox="0 0 16 16"
                      lc-helper="svg-icon"
                    >
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                    </svg>
                    <p editable="inline" className="lead my-auto">
                      Be part of the solution&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lc-block">
            <a
              className="btn text-white btn-lg btn-primary"
              href="#pricing"
              role="button"
              onClick={handleShow}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Stay Informed on our Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullname"
                placeholder="Jan Smith"
                autoFocus
                onChange={handleChange}
              />
              {errors.fullname && <div className="text-danger">{errors.fullname}</div>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="name@example.com"
                autoFocus
                onChange={handleChange}
              />
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" name="city" onChange={handleChange} />
                {errors.city && <div className="text-danger">{errors.city}</div>}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  size=""
                  defaultValue="Choose..."
                  name="state"
                  onChange={handleChange}
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
                {errors.state && <div className="text-danger">{errors.state}</div>}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control type="text" name="zip" onChange={handleChange} />
                {errors.zip && <div className="text-danger">{errors.zip}</div>}
              </Form.Group>
            </Row>
            <fieldset>
              <Form.Group
                as={Row}
                name="role"
                className="mb-3"
                onChange={handleChange}
              >
                <Form.Label as="legend" column sm={4}>
                  What's your role?
                </Form.Label>
                <Col sm={6}>
                  <Form.Check
                    type="radio"
                    label="Restaurant Owner"
                    value="Restaurant Owner"
                    onChange={handleChange}
                    name="role"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    type="radio"
                    label="Restaurant Manager"
                    value="Restaurant Manager"
                    onChange={handleChange}
                    name="role"
                    id="formHorizontalRadios2"
                  />
                  <Form.Check
                    type="radio"
                    label="Restaurant Employee"
                    value="Restaurant Employee"
                    onChange={handleChange}
                    name="role"
                    id="formHorizontalRadios3"
                  />
                </Col>
                {errors.role && <div className="text-danger">{errors.role}</div>}
              </Form.Group>
            </fieldset>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Why would this app help you?</Form.Label>
              <Form.Control
                as="textarea"
                name="comment"
                rows={3}
                onChange={handleChange}
              />
              {errors.comment && <div className="text-danger">{errors.comment}</div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CallToAction;
