import axios from "axios";

const BACKEND_URL =
  "https://speak-your-menu-default-rtdb.firebaseio.com/";

  export async function storeUser(userData) {
    const response = await axios.post(BACKEND_URL + "users.json", userData);
    console.log("🚀 ~ storeUser ~ response:", response)
  }

  export async function getUsers() {
    const response = await axios.get(BACKEND_URL + 'users.json');
    
    const usersData = [];

    // console.log("🚀 ~ fetchExpenses ~ response.data:", response.data)
    for (const key in response.data) {
      const userObj = {
        id: key,
        fullname: response.data[key].fullname,
        email: response.data[key].email,
        role: response.data[key].role,
        comment: response.data[key].comment,
        city: response.data[key].city,
        state: response.data[key].state,
        zip: response.data[key].zip,

      };
      usersData.push(userObj);
    }
  
    return usersData;
  }