import React from "react";

import CallToAction from "./CallToAction";
import ContentSection from "./ContentSection";
import Features from "./Features";
import Hero from "./Hero";
import Statistics from "./Statistics";
import Video from "./Video";
import Nav from "./Nav";
import Footer from "./Footer";

function MainPage() {
  return (
    <div>
      <Nav />
      <Hero />
      <Statistics />
      <ContentSection />
      <Features />
      <Video />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default MainPage;
