import React from 'react'

import photo1 from '../assets/content_2.jpg'
import photo2 from '../assets/content_1.png'
import '../styles/contentSection.css'

function ContentSection() {
  return (
    <div className="container-fluid py-5 overflow-hidden">
        <div className="row flex-lg-row-reverse g-0 align-items-center justify-content-around">
            <div className="col-lg-6 ">
                <div className="lc-block row justify-content-center">
                    <img className="content-image d-md-none img-fluid w-50" src={photo1} sizes="" width="" height="" alt="Restaruant scene" loading="lazy"/>
                    <img className="content-image d-none d-md-block img-fluid w-50" src={photo1} sizes="" width="" height="" alt="Restaruant scene" loading="lazy"/>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="lc-block p-3">
                    <div editable="rich">
                        <h1>A New Way to Train Your Staff</h1>
                        <p className="lead">Lesson based progressive education application that focusses soley on teaching the menu of the individual restaurant. By teaching the menu as an individual language and focussing on repitition and reccolecion we increase sales per customer and the overall revenue of the restuarant. The mobile application manages the consistency of the staff education.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row g-0 align-items-center justify-content-around">
            <div className="col-lg-5">
                <div className="lc-block row justify-content-center">
                    <img className="content-image d-md-none img-fluid w-50" src={photo2} sizes=""  height="" alt="Restaruant scene" loading="lazy"/>
                    <img className="content-image d-none d-md-block img-fluid w-50" src={photo2} sizes=""  height="" alt="Restaruant scene" loading="lazy"/>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="lc-block p-3">
                    <div editable="rich">
                        <h1>The Process is Easy</h1>
                        <p className="lead">Simply take a picture of a bottle of wine and the app scans it for all the valuable educational material or put in your ingediants for a signature dish and Speak Your Menu creates a series of lessons that teach your menu as if it were it's own language.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContentSection