import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from 'react-bootstrap/Modal';

import Footer from "./Footer";
import Nav from "./Nav";

function ContactUs() {
  const [errors, setErrors] = useState({});
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const sendEmail = () => {
    var templateParams = {
      name: userInfo.name,
      email: userInfo.email,
      subject: userInfo.subject,
      message: userInfo.message,
    };

    emailjs
      .send(
        "service_bk8ljie",
        "template_gu740jm",
        templateParams,
        "PByxhVdkuDB9scoXI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({ ...prevState, [name]: value }));
  }

  function isValidEmail(email) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let validationErrors = {};

    if (userInfo.email.length === 0 || !isValidEmail(userInfo.email)) {
      validationErrors.email = 'Email is invalid.'
    }

    if (userInfo.name.length === 0){
      validationErrors.name = 'Please enter a name.'
    }

    if (userInfo.subject.length === 0) {
      validationErrors.subject = 'Please enter a subject.'
    }

    if (userInfo.message.length === 0) {
      validationErrors.message = 'Please enter a message.'
    }

    
    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors)
      console.log("Ammount of Errors:", Object.keys(validationErrors).length)
      setErrors(validationErrors);
      console.log(errors)
    } else {
      // Handle the successful form submission, e.g., sending formData to a server
      try {
       await sendEmail();
       console.log("uerInfo:", userInfo)
       handleShow();
       setUserInfo({
        name: "",
        email: "",
        subject: "",
        message: "",
      })
      } catch(error) {
        console.log("🚀 ~ handleSubmit ~ error:", error.message)
        
      }
      console.log('Data sent.')
      setErrors({}); // Clear any previous errors
    }
  }


  return (
    <div>
      <Nav />
      <section className=" my-4">
        <div className="container py-5">
          <div className="p-5 lc-block">
            <div className="lc-block">
              <div editable="rich">
                <h2 className="fw-bolder display-3">Contact us</h2>
              </div>
            </div>
            <div className="lc-block col-md-8">
              <div editable="rich">
                <p className="lead">
                  Let us know if you have any questions about Speak Your Menu.
                  We will email you back.
                </p>
              </div>
            </div>
          </div>
          <div className="p-5 lc-block shadow rounded-3 col-xl-10 offset-xl-1">
            <form>
              <div className="form-group mb-4">
                <label>Your Name</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="John Doe"
                  value={userInfo.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>

              <div className="form-group mb-4">
                <label>Your Email Address</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="name@example.com"
                  value={userInfo.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>

              <div className="form-group mb-4">
                <label>Subject</label>
                <input
                  name="subject"
                  type="text"
                  className="form-control"
                  placeholder="Contact Subject"
                  value={userInfo.subject}
                  onChange={handleChange}
                />
                {errors.subject && <div className="text-danger">{errors.subject}</div>}
              </div>

              <div className="form-group mb-4">
                <label>Your Message</label>
                <textarea
                  name="message"
                  className="form-control"
                  rows="3"
                  maxLength="500"
                  value={userInfo.message}
                  onChange={handleChange}
                ></textarea>
                <p>{userInfo.message.length}/500</p>
                {errors.message && <div className="text-danger">{errors.message}</div>}
              </div>

              <button type="submit" className="btn btn-primary btn-lg" onClick={handleSubmit}>
                Submit Form
              </button>
            </form>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>We'll get back to you in 2-3 days!</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
}

export default ContactUs;
