import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import About from "./components/About";
import PageNotFound from "./components/PageNotFound";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";
import Admin from "./components/Admin";
import ContactUs from "./components/ContactUs";

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <MainPage /> },
    { path: "/about", element: <About /> },
    { path: "/Char3tt34su", element: <Admin /> },
    { path: "/contact", element: <ContactUs /> },
    { path: "*", element: <PageNotFound /> },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
