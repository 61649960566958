import React from "react";

import heroImage from "../assets/hero_image.png";

function Hero() {
  return (
    <div className="overflow-hidden ">
      <div className="container-fluid col-xxl-8">
        <div className="row flex-lg-nowrap align-items-center g-5">
          <div
            className="order-lg-1 w-50" style={{marginRight: 'auto', marginLeft: 'auto'}}>
            <img
              className="img-fluid w-100"
              src={heroImage}
              alt="Restaruant scene"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
            <div className="lc-block mb-4">
              <div editable="rich">
                <h1 className="fw-bold display-3">
                  Speak Your Menu
                </h1>
              </div>
            </div>

            <div className="lc-block mb-5">
              <div editable="rich">
                <p className="rfs-8">
                  {" "}
                  Teach your staff the how to speak the language of your menu. By treating your menu as an individual language you can increase your team's ability to answer customer questions, increase sales and communicate with your customers more effeciently.
                </p>
              </div>
            </div>

            {/* <div className="lc-block mb-6">
              <a
                className="btn btn-primary px-4 me-md-2 btn-lg"
                href="#"
                role="button"
              >
                Find out more
              </a>
            </div> */}

            {/* //TODO: Turn this on when you get some first users. */}
            {/* <div className="lc-block">
              <div editable="rich">
                <p className="fw-bold">
                  {" "}
                  Business collaboration based on trust:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="lc-block col-3">
                <img
                  className="img-fluid wp-image-975"
                  src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/motorola.svg"
                  width=""
                  height="300"
                  srcset=""
                  sizes=""
                  alt=""
                />
              </div>
              <div className="lc-block col-3">
                <img
                  className="img-fluid wp-image-977"
                  src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/asus.svg"
                  width=""
                  height="300"
                  srcset=""
                  sizes=""
                  alt=""
                />
              </div>
              <div className="lc-block col-3">
                <img
                  className="img-fluid wp-image-974"
                  src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/sony.svg"
                  width=""
                  height="300"
                  srcset=""
                  sizes=""
                  alt=""
                />
              </div>
              <div className="lc-block col-3">
                <img
                  className="img-fluid wp-image-967"
                  src="https://lclibrary.b-cdn.net/starters/wp-content/uploads/sites/15/2021/11/samsung-282297.svg"
                  width=""
                  height="300"
                  srcset=""
                  sizes=""
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
