import React from "react";
import aboutPhoto from "../assets/about_photo.jpeg";
import Footer from "./Footer";
import Nav from "./Nav";

function About() {
  return (
    <>
      <Nav />
      <section style={{marginTop: '100px'}}>
        <div
          className="d-flex container-fluid"
          lc-helper="background"
          style={{
            height: "80vh",
            backgroundImage: `url(${aboutPhoto})`,
            backgroundPosition: "50% 65%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "-100px",
          }}
        ></div>
        <div
          className="container bg-light shadow py-4 rounded mb-5"
          style={{ marginTop: "-100px" }}
        >
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <div className="lc-block">
                <div editable="rich">
                  <p className="fw-bold display-5">Who We Are</p>
                </div>
              </div>
            </div>
            <div className="lc-block col-5">
              <hr />
            </div>
          </div>
          <div className="row text-center justify-content-center">
            <div className="col-lg-10 col-xxl-8">
              <div className="lc-block mb-5">
                <div editable="rich">
                  <p className="rfs-9 fs-4">
                    {" "}
                    We have spent an entire career trying to become
                    knowledgeable about hospitality and its products. During
                    that time we have educated thousands of restaurant employees
                    on hospitality skill set. We have come to the conclusion
                    that by treating an individual restaurants menu as a
                    language and teaching it in a progressive lesson based
                    program increases profits and staff retention faster than
                    any other method.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
